
  export default {
    name:'WatFocusConnectivityTest',
    props:{
        testTile:{
            type: String,
            default: 'WATChanceFocus | <span>Lanzamiento nueva App</span>'
        }
    },
    data() {
      return {
        currentStep:1,
        microVolume:0,
        microStream:null,
        cameraStream:null,
        audioSrc:null,
        networkData:{
            upload: 0,
            download:0
        },
        shareScreenText:this.$t('wat_focus_connectivity_button_share'),
        networkButtonText:this.$t('wat_focus_connectivity_button_network'),
        devices:{
            camera:{
                name:'',
                id:0
            },
            micro:{
                name:'',
                id:0
            },
            speaker:{
                name:'',
                id:0
            }
        },
        testErrors:{
            micro:false,
            camera:false,
            network:false,
            screen:false
        },
        error: true
      };
    },
    computed:{
        buttonText(){
            return this.currentStep == 5 ? this.$t('wat_focus_connectivity_complete') : this.$t('wat_focus_connectivity_next')
        }
    },
    methods: {
      nextStep(){
        this.error = true
        if(this.currentStep == 1) this.saveStep1()
        else if(this.currentStep == 2) this.saveStep2()
        else if(this.currentStep == 3) this.saveStep3()
        else if(this.currentStep == 4) this.saveStep4()
        else if(this.currentStep == 5) this.saveStep5()
    
      },
      saveStep1(){
        this.currentStep++
        this.microStream.getTracks().forEach(track => track.stop());
        this.testCamera()
      },
      saveStep2(){
        this.currentStep++
        this.cameraStream.getTracks().forEach(track => track.stop());
      },
      saveStep3(){
        this.currentStep++

      },
      saveStep4(){
        this.currentStep++

      },
      saveStep5(){
        this.currentStep++
        this.$emit('completed')

      },
      async testMicrophone() {
        const vm = this
        this.testErrors.micro = false
        this.error = false
        navigator.mediaDevices.getUserMedia({ audio: true }).then(function(stream) {
            vm.microStream = stream
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();

            source.connect(analyser);
            analyser.fftSize = 256;

            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            const detectarSonido = () => {
                analyser.getByteFrequencyData(dataArray);
                const averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
                vm.microVolume = (averageVolume / 255) * 500;
            };

            setInterval(detectarSonido, 200);
            let audioTrack = stream.getAudioTracks()[0];
            vm.devices.micro.id = audioTrack.getSettings().deviceId;            
            return navigator.mediaDevices.enumerateDevices();
        })
        .then(function(devices) {
            devices.forEach(function(device) {
                if (device.kind === "audioinput") {
                    if (device.deviceId === vm.devices.micro.id) {
                        vm.devices.micro.name = device.label
                    }
                }
            });
        }).catch(function(err) {
            vm.error = true
            vm.testErrors.micro = true
        });
      },
      async testCamera() {
        const vm = this
        vm.error = false
        vm.testErrors.camera = false
        await navigator.mediaDevices.getUserMedia({ video: true })
        .then(function(stream) {
            vm.cameraStream = stream
            const video: any = vm.$refs.video 
            video.srcObject = stream
            video.play()
            let videoTrack = stream.getVideoTracks()[0];
            vm.devices.camera.id = videoTrack.getSettings().deviceId;            
            return navigator.mediaDevices.enumerateDevices();
        })
        .then(function(devices) {
            devices.forEach(function(device) {
                if (device.kind === "videoinput") {
                    if (device.deviceId === vm.devices.camera.id) {
                        vm.devices.camera.name = device.label
                    }
                }
            });
        }).catch(function(err) {
            vm.error = true
            vm.testErrors.camera = true
        });
      },
      async shareScreen() {
        this.error = false
        this.testErrors.screen = false
        this.shareScreenText = await this.$t('wat_focus_connectivity_button_sharing')
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia();
          stream.getTracks().forEach(track => track.stop());
          this.shareScreenText = this.$t('wat_focus_connectivity_button_finished')
        } catch (error) {
          this.error = true
          this.testErrors.screen = true
          this.shareScreenText = this.$t('wat_focus_connectivity_button_share')
        }
      },
      async testNetwork() {
        this.error = false
        this.testErrors.network = false
        this.networkButtonText = await this.$t('wat_focus_connectivity_button_network_loading')
        let vm = this
        try {
            const downloadUrl = 'https://wat-files-dev.s3.eu-central-1.amazonaws.com/wat_focus_download_image.jpg';
            const downloadSizeBytes = 4000000;

            const downloadStartTime = Date.now();
            const downloadResponse = await fetch(downloadUrl);
            const downloadEndTime = Date.now();
            
            const downloadDuration = (downloadEndTime - downloadStartTime) / 1000;
            const downloadSpeedMbps = (downloadSizeBytes * 8) / (downloadDuration * 1024 * 1024);
            vm.networkData.download = downloadSpeedMbps.toFixed(2)
            const uploadUrl = 'https://api.wearetesters.com/file';
            const uploadSizeBytes = 5000000;
            const uploadData = new Uint8Array(uploadSizeBytes);

            const uploadStartTime = Date.now();
            await fetch(uploadUrl, {
            method: 'POST',
            body: uploadData,
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            });
            const uploadEndTime = Date.now();

            const uploadDuration = (uploadEndTime - uploadStartTime) / 1000;
            const uploadSpeedMbps = (uploadSizeBytes * 8) / (uploadDuration * 1024 * 1024);

            vm.networkData.upload = uploadSpeedMbps.toFixed(2)

            vm.networkButtonText = await this.$t('wat_focus_connectivity_button_network_finished')
        } catch (error) {
            this.error = true
            this.testErrors.network = true
            vm.networkButtonText = vm.$t('wat_focus_connectivity_button_network')
        }
        },
      async testAudio() {
        this.error = false
        this.testErrors.audio = false
        try {
          const context = new (window.AudioContext || window.webkitAudioContext)();
          const oscillator = context.createOscillator();
          oscillator.type = 'sine';
          oscillator.frequency.setValueAtTime(440, context.currentTime); 
          oscillator.connect(context.destination);
          oscillator.start();
          oscillator.stop(context.currentTime + 1);
  
          const source = context.createMediaStreamDestination();
          oscillator.connect(source);
          this.audioSrc = URL.createObjectURL(new Blob([source.stream]));
        } catch (error) {
            this.error = true
            this.testErrors.audio = true
        }
      }
    },
    mounted(){
        this.testMicrophone()
    }
  };
  