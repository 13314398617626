
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

export default defineComponent({
    props: ['test'],
    setup(props, { emit }) {
        const router: any = useRouter()
        const goToTest = (id: string, type: string) => {
            if(type == "FOCUS_TEST") {
                emit('open-focus-test', id)
            }
            else router.push({ name: 'test.show', params: { id } })
        }
        return {
            goToTest,
            moment,
        }
    },
})
