
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

export default defineComponent({
    props: ['test'],
    setup() {
        const router: any = useRouter()
        const goToTest = (id: string) => {
            router.push({ name: 'test.show', params: { id } })
        }
        return {
            goToTest,
            moment,
        }
    },
})
