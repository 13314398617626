
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import TestBox from '@/components/TestBox.vue'
import TestBoxMobile from '@/components/TestBoxMobile.vue'
import PaginationWat from '@/components/PaginationWat.vue'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
    components: {
        TestBox,
        TestBoxMobile,
        PaginationWat,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
    },
    setup() {
        const testsStore: any = inject('testsStore')
        const page = ref(1)
        const infinityPage = ref(0)
        const totalTests = computed(() => {
            const numericCount = Number(testsStore.state.testsState.count);
            return numericCount;
        });
        const isInfiniteScrollDisabled = ref(false)
        const isApp = ref(false)
        const isMobileView = ref(false)

        const loadTests = async (e: any, mobile: boolean) => {
            if (mobile) {
                infinityPage.value++
                await testsStore.getTests(infinityPage.value, {}, mobile)
                e.target.complete()
                if (
                    infinityPage.value ==
                    testsStore.state.testsState.maxPages - 1
                ) {
                    isInfiniteScrollDisabled.value = true
                }
                return
            }
            testsStore.state.testsState.loading = true
            await testsStore.getTests(page.value - 1, {}, mobile)
        }

        const handleResizeChange = () => {
            if (window.innerWidth < 600) {
                isMobileView.value = true
            } else {
                isMobileView.value = false
            }
        }
        onBeforeMount(async () => {
            isApp.value = Capacitor.isNativePlatform()
            if (window.innerWidth < 600) {
                isMobileView.value = true
            }
            window.addEventListener('resize', handleResizeChange)
        })
        return {
            tests: computed(() => testsStore.state.testsState.tests),
            page,
            totalTests,
            loadTests,
            isInfiniteScrollDisabled,
            isApp,
            isMobileView,
        }
    },
})
