<template>
    <div class="WatFocusWelcomePage">
            <!--<Execution :testId="testId"/>
            <WatFocusTesterCalendar v-on:completed="confirmCalendarDates($event)"/>-->
            <WatFocusConectivityTest v-on:completed="saveConectivityTest($event)" />
    </div>
</template>
<script>   
    import Execution from './Execution.vue'
    import WatFocusConectivityTest from './WatFocusConectivityTest'
   export default {
    name: 'WatFocusTestExecution',
    components: { 
        Execution,
        WatFocusConectivityTest
    },
    props: {
        testId:[String, Number]
    },
    methods:{
        confirmCalendarDates(){

        },
        saveConectivityTest(){

        }

    }
   }
</script>
<style lang="scss" scoped>

</style>
   